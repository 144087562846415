import { i18n } from "@/lib/i18n";
import store from "@/store";
import { getCleansedDate, getHarvestDate, PythagorasEquirectangular, utcDate } from "@/utils";
import moment from "moment";
import { defineStore } from "pinia";
import { Planting } from "seedgreen-shared/models/Planting";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

export const useLotSearchStore = defineStore(
	"lotSearch",
	() => {
		const plantings = computed(() => store.state.regions);

		const sortBy = ref<"proximity" | "name" | "wetDate" | "harvestDate">("proximity");
		const districtFilter = ref<string[]>(["all"]);
		const cropFilter = ref<string[]>(["all"]);
		const growerFilter = ref<string[]>(["all"]);
		const harvestWeekFilter = ref<string[]>(["all"]);
		const searchFilter = ref<string>("");
		const displayField = ref<"proximity" | "wetDate" | "harvestWeek" | "harvestDate" | "commodity">("proximity");

		function $reset() {
			sortBy.value = "proximity";
			districtFilter.value = ["all"];
			cropFilter.value = ["all"];
			growerFilter.value = ["all"];
			harvestWeekFilter.value = ["all"];
			searchFilter.value = "";
			displayField.value = "proximity";
		}

		// Filter store plantings down to > 3 months ago
		const relevantPlantings = computed<any[]>(() => {
			const now = moment();
			const threeMonthsAgo = moment().subtract(3, "months");
			return (
				plantings.value?.filter((obj) => {
					return Object.keys(obj).some(
						(key) =>
							key.toLowerCase().includes("date") &&
							typeof obj[key] === "string" &&
							(moment(obj[key]).isBetween(threeMonthsAgo, now) || moment(obj[key]).isAfter(now)),
					);
				}) ?? []
			);
		});

		const filteredPlantings = computed<any[]>(() => {
			const shouldFilter = (filter: string[], value: any) => {
				return !filter.some(
					(x) =>
						x == "all" || // don't filter if 'all' is selected
						x == value || // don't filter if the value matches one of the selecctions
						(value.includes && value.includes(x)), // don't filter if an array of values contains one of the selections
				);
			};

			const result = plantings.value.filter((planting) => {
				// blocks
				if (planting.isIrrigationBlock) return false;

				// district
				if (shouldFilter(districtFilter.value, [planting.environmentalRegionName, planting.ranch?.name]))
					return false;

				// crop
				if (shouldFilter(cropFilter.value, planting.plantedCrop.commodityId)) return false;

				// grower
				if (shouldFilter(growerFilter.value, planting.grower?.id)) return false;

				// harvest week
				if (shouldFilter(harvestWeekFilter.value, getHarvestWeek(planting).toString())) return false;

				// search
				const _searchFilter = searchFilter.value?.trim().toLowerCase();

				if (_searchFilter) {
					if (
						!_searchFilter.split(" ").every((term) => {
							return (
								(planting.plantingRegion || "").toLowerCase().indexOf(term) !== -1 ||
								(planting.environmentalRegionName || "").toLowerCase().indexOf(term) !== -1
							);
						})
					)
						return false;
				}

				return true;
			});
			return result;
		});

		const sortedPlantings = computed<any[]>(() => {
			switch (sortBy.value) {
				case "proximity":
					if (store.state.currentLocation) {
						const sorted = [...filteredPlantings.value].sort((a, b) => {
							return getDistanceFromCurrentLocation(a) - getDistanceFromCurrentLocation(b);
						});
						return sorted;
					}
					break;
				case "wetDate": {
					const sorted = [...filteredPlantings.value].sort((a, b) => {
						return new Date(a.wetDateRaw).getTime() - new Date(b.wetDateRaw).getTime();
					});
					return sorted;
				}
				case "harvestDate": {
					const sorted = [...filteredPlantings.value].sort((a, b) => {
						return (
							(getHarvestDate(a, false, "statistics")?.getTime() ?? 0) -
							(getHarvestDate(b, false, "statistics")?.getTime() ?? 0)
						);
					});
					return sorted;
				}
				default: {
					// name
					const sorted = [...filteredPlantings.value].sort((a, b) => {
						return a.plantingRegion.localeCompare(b.plantingRegion);
					});
					return sorted;
				}
			}

			return filteredPlantings.value;
		});

		const alertedPlantingIds = computed<Set<number>>(() => {
			return new Set(store.state.alerts?.map((alert) => alert.regionId) || []);
		});

		function getHarvestWeek(planting: any) {
			const harvestDate = getHarvestDate(planting, false, "statistics");
			return store.getters.newWeekOfYear(harvestDate, store.state.user?.startDayOfWeek);
		}

		function getDistanceFromCurrentLocation(planting: any) {
			return PythagorasEquirectangular(
				planting.lotCenter.latitude,
				planting.lotCenter.longitude,
				store.state.currentLocation?.coords.latitude,
				store.state.currentLocation?.coords.longitude,
			);
		}

		function hasDisplayField(planting: Partial<Planting>) {
			if (displayField.value === "proximity") return Boolean(store.state.currentLocation);

			return Boolean(getDisplayField(planting));
		}

		function numberFormat(value: number, decimals: number) {
			return Math.round(value * 10 ** decimals) / 10 ** decimals;
		}

		function getDisplayField(planting: any) {
			const t = i18n.global.t;

			const harvestDate = getHarvestDate(planting, false, "statistics");

			switch (displayField.value) {
				case "proximity":
					return (
						numberFormat(getDistanceFromCurrentLocation(planting), 1) +
						" " +
						t("measure.mile", getDistanceFromCurrentLocation(planting)).toLowerCase()
					);
				case "wetDate":
					return utcDate(planting.wetDateRaw);
				case "harvestWeek":
					return t("time.week-x", {
						x: store.getters.newWeekOfYear(harvestDate, store.state.user?.startDayOfWeek).week,
					});
				case "harvestDate":
					return harvestDate?.toLocaleDateString();
				case "commodity":
					return store.state.customerCommodityAliases?.[planting.plantedCrop.commodityId];
			}
		}

		function isPlanted(planting: Partial<Planting>) {
			const wetActualized =
				planting.wetDateActual &&
				moment(getCleansedDate(planting.wetDateActual)).isSameOrBefore(moment(), "day");
			const plantActualized =
				planting.plantDateActual &&
				moment(getCleansedDate(planting.plantDateActual)).isSameOrBefore(moment(), "day");
			const transplantActualized =
				planting.transplantDateActual &&
				moment(getCleansedDate(planting.transplantDateActual)).isSameOrBefore(moment(), "day");

			return wetActualized || plantActualized || transplantActualized;
		}

		function hasNoteImages(planting: Partial<Planting>) {
			// Get planting's notes
			const notes = (store.state.noteIdsByPlantingIds?.[planting.id as number] ?? []).map(
				(noteId: number) => store.state.notesById?.[noteId],
			);

			// Return true if the planting has notes and if at least one note has images
			return notes.length && notes.some((note) => note.noteImages && note.noteImages.length);
		}

		return {
			$reset,

			districtFilter,
			cropFilter,
			growerFilter,
			harvestWeekFilter,
			sortBy,
			searchFilter,
			displayField,

			relevantPlantings,
			filteredPlantings,
			sortedPlantings,
			alertedPlantingIds,

			hasDisplayField,
			getDisplayField,
			getDistanceFromCurrentLocation,
			isPlanted,
			hasNoteImages,
		};
	},
	{ persist: true },
);
