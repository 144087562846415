<template>
	<ion-page>
		<ion-header>
			<ion-toolbar color="branding">
				<ion-button
					slot="end"
					color="transparent"
					style="margin-right: 8px; overflow: hidden"
					@click="dismissModal"
				>
					Done
				</ion-button>
				<ion-title>
					{{ title }}
				</ion-title>
			</ion-toolbar>
		</ion-header>
		<ion-content class="">
			<div ref="selectionMap" id="selection-map" style="width: 100%; height: 100%"></div>
			<div class="map-buttons-wrapper" style="overflow-x: auto; white-space: nowrap; margin: 8px">
				<span class="icon-box small" style="background: #387ef5; color: #eee" @click="drawPolygon()">
					<i class="fa fa-draw-polygon"></i>
				</span>
				<span class="icon-box small" style="background: #c60; color: #eee" @click="placeMarker()">
					<i class="fa fa-map-marker-alt"></i>
				</span>
				<span
					class="icon-box small"
					style="background: #a00; color: #eee"
					@click="removeLocation()"
					v-if="noteLocation || addingNoteMarker || addingNotePolygon"
				>
					<i class="fa fa-times"></i>
				</span>
			</div>
		</ion-content>
	</ion-page>
</template>

<style>
	.icon-box.small {
		height: 50px;
		width: 50px;
	}
	.icon-box.small > i {
		font-size: 22px;
	}

	.map-buttons-wrapper {
		position: absolute;
		top: 0;
	}

	i.fa-check-circle,
	i.fa-circle {
		font-size: 24px;
		margin-right: 12px;
	}

	ion-item.map-filter > ion-toggle {
		zoom: 0.8;
	}

	ion-item.map-filter {
		font-size: 16px;
	}
</style>

<script>
	import mapboxgl from "mapbox-gl";
	import "mapbox-gl/dist/mapbox-gl.css";

	import MapboxDraw from "@mapbox/mapbox-gl-draw";

	import * as turf from "@turf/turf";

	import { mapState } from "vuex";

	import { mapboxToken } from "seedgreen-shared-scripts";
	import { defineAsyncComponent } from "vue";
	import { IonTitle, IonButton, IonToolbar, IonHeader, IonContent, IonPage, modalController } from "@ionic/vue";
	import { captureException } from "@sentry/vue";

	export default {
		name: "SelectNoteLocation",
		components: {
			IonTitle,
			IonButton,
			IonToolbar,
			IonHeader,
			IonContent,
			IonPage,
		},
		props: {
			//TODO: use onWillDismiss and get rid of parent
			parent: { type: Object },
			user: { type: Object },
		},
		data() {
			return {
				noteLocation: null,
				addingNoteMarker: false,
				addingNotePolygon: false,
				map: null,
				mode: "display",
				mapLoaded: false,
				mapVisiblePlantings: null,
				filterByMapView: false,
				searchQuery: "",
			};
		},
		created() {
			mapboxgl.accessToken = mapboxToken;
		},
		beforeMounted() {},
		mounted() {
			this.noteLocation = this.parent.noteLocation;
			const mapParams = {
				container: this.$refs.selectionMap,
				style: "mapbox://styles/mapbox/streets-v11",
				zoom: 14,
			};

			if (this.currentLocation && this.currentLocation.coords)
				mapParams.center = [this.currentLocation.coords.longitude, this.currentLocation.coords.latitude];

			this.map = new mapboxgl.Map(mapParams);

			// Show zoom/orientation controls
			this.map.addControl(new mapboxgl.NavigationControl());

			// Show "locate me" control
			this.map.addControl(
				new mapboxgl.GeolocateControl({
					positionOptions: { enableHighAccuracy: true },
					trackUserLocation: true,
				}),
			);

			this.map.on("load", () => {
				// Load icons
				Promise.all(
					Object.entries({
						"weather-station": "/images/markers/weather-station-icon.png",
						"marker-new": "/images/markers/marker-new.png",
						"marker-hover": "/images/markers/marker-hover.png",
						marker: "/images/markers/marker.png",
						photo: "/images/markers/photo.png",
						"photo-hover": "/images/markers/photo-hover.png",
					}).map(
						(img) =>
							new Promise((resolve) => {
								this.map.loadImage(img[1], (error, res) => {
									this.map.addImage(img[0], res);
									resolve();
								});
							}),
					),
				).then(() => {
					this.mapLoaded = true;

					if (!this.map.center) this.centerMapOnFilteredPlantings();

					this.centerMapOnSelectedPlantings();

					// Load planting boundaries onto map
					this.map.addSource("plantings", {
						type: "geojson",
						data: {
							type: "FeatureCollection",
							features: [],
						},
					});

					this.map.addLayer({
						id: "planting-fills",
						type: "fill",
						source: "plantings",
						layout: {},
						minZoom: 1,
						paint: {
							"fill-color": ["case", ["get", "selected"], "#EAD315", "#627BC1"],
							"fill-opacity": 0.6,
						},
					});

					this.map.addLayer({
						id: "planting-borders",
						type: "line",
						source: "plantings",
						layout: {},
						paint: {
							"line-color": ["case", ["get", "selected"], "#888888", "#627BC1"],
							"line-width": 1.5,
						},
					});

					try {
						this.map.draw = new MapboxDraw({
							displayControlsDefault: false,
							styles: [
								{
									id: "gl-draw-polygon-fill-inactive",
									type: "fill",
									filter: [
										"all",
										["==", "active", "false"],
										["==", "$type", "Polygon"],
										["!=", "mode", "static"],
									],
									paint: {
										"fill-color": "#3bb2d0",
										"fill-outline-color": "#3bb2d0",
										"fill-opacity": 0.1,
									},
								},
								{
									id: "gl-draw-polygon-fill-active",
									type: "fill",
									filter: ["all", ["==", "active", "true"], ["==", "$type", "Polygon"]],
									paint: {
										"fill-color": "#fbb03b",
										"fill-outline-color": "#fbb03b",
										"fill-opacity": 0.1,
									},
								},
								{
									id: "gl-draw-polygon-midpoint",
									type: "circle",
									filter: ["all", ["==", "$type", "Point"], ["==", "meta", "midpoint"]],
									paint: {
										"circle-radius": 3,
										"circle-color": "#fbb03b",
									},
								},
								{
									id: "gl-draw-polygon-stroke-inactive",
									type: "line",
									filter: [
										"all",
										["==", "active", "false"],
										["==", "$type", "Polygon"],
										["!=", "mode", "static"],
									],
									layout: {
										"line-cap": "round",
										"line-join": "round",
									},
									paint: {
										"line-color": "#3bb2d0",
										"line-width": 2,
									},
								},
								{
									id: "gl-draw-polygon-stroke-active",
									type: "line",
									filter: ["all", ["==", "active", "true"], ["==", "$type", "Polygon"]],
									layout: {
										"line-cap": "round",
										"line-join": "round",
									},
									paint: {
										"line-color": "#fbb03b",
										"line-dasharray": [0.2, 2],
										"line-width": 2,
									},
								},
								{
									id: "gl-draw-line-inactive",
									type: "line",
									filter: [
										"all",
										["==", "active", "false"],
										["==", "$type", "LineString"],
										["!=", "mode", "static"],
									],
									layout: {
										"line-cap": "round",
										"line-join": "round",
									},
									paint: {
										"line-color": "#3bb2d0",
										"line-width": 2,
									},
								},
								{
									id: "gl-draw-line-active",
									type: "line",
									filter: ["all", ["==", "$type", "LineString"], ["==", "active", "true"]],
									layout: {
										"line-cap": "round",
										"line-join": "round",
									},
									paint: {
										"line-color": "#fbb03b",
										"line-dasharray": [0.2, 2],
										"line-width": 2,
									},
								},
								{
									id: "gl-draw-polygon-and-line-vertex-stroke-inactive",
									type: "circle",
									filter: [
										"all",
										["==", "meta", "vertex"],
										["==", "$type", "Point"],
										["!=", "mode", "static"],
									],
									paint: {
										"circle-radius": 5,
										"circle-color": "#fff",
									},
								},
								{
									id: "gl-draw-polygon-and-line-vertex-inactive",
									type: "circle",
									filter: [
										"all",
										["==", "meta", "vertex"],
										["==", "$type", "Point"],
										["!=", "mode", "static"],
									],
									paint: {
										"circle-radius": 3,
										"circle-color": "#fbb03b",
									},
								},
								{
									id: "gl-draw-point-point-inactive",
									type: "symbol",
									filter: [
										"all",
										["==", "active", "false"],
										["==", "$type", "Point"],
										["==", "meta", "feature"],
										["!=", "mode", "static"],
									],
									layout: {
										"icon-image": "marker-new",
									},
								},
								{
									id: "gl-draw-point-active",
									type: "symbol",
									filter: [
										"all",
										["==", "$type", "Point"],
										["!=", "meta", "midpoint"],
										["!=", "meta", "vertex"],
										["==", "active", "true"],
									],
									layout: {
										"icon-image": "marker-new",
									},
								},
								{
									id: "gl-draw-point-stroke-active-vertex",
									type: "circle",
									filter: [
										"all",
										["==", "$type", "Point"],
										["==", "active", "true"],
										["==", "meta", "vertex"],
									],
									paint: {
										"circle-radius": 7,
										"circle-color": "#fff",
									},
								},
								{
									id: "gl-draw-point-active-vertex",
									type: "circle",
									filter: [
										"all",
										["==", "$type", "Point"],
										["==", "meta", "vertex"],
										["==", "active", "true"],
									],
									paint: {
										"circle-radius": 5,
										"circle-color": "#fbb03b",
									},
								},
								{
									id: "gl-draw-polygon-fill-static",
									type: "fill",
									filter: ["all", ["==", "mode", "static"], ["==", "$type", "Polygon"]],
									paint: {
										"fill-color": "#404040",
										"fill-outline-color": "#404040",
										"fill-opacity": 0.1,
									},
								},
								{
									id: "gl-draw-polygon-stroke-static",
									type: "line",
									filter: ["all", ["==", "mode", "static"], ["==", "$type", "Polygon"]],
									layout: {
										"line-cap": "round",
										"line-join": "round",
									},
									paint: {
										"line-color": "#404040",
										"line-width": 2,
									},
								},
								{
									id: "gl-draw-line-static",
									type: "line",
									filter: ["all", ["==", "mode", "static"], ["==", "$type", "LineString"]],
									layout: {
										"line-cap": "round",
										"line-join": "round",
									},
									paint: {
										"line-color": "#404040",
										"line-width": 2,
									},
								},
								{
									id: "gl-draw-point-static",
									type: "symbol",
									filter: ["all", ["==", "mode", "static"], ["==", "$type", "Point"]],
									layout: {
										"icon-image": "marker-new",
									},
								},
							],
						});

						this.map.addControl(this.map.draw);

						this.map.on(
							"draw.create",
							function (e) {
								this.mode = "display";
								//limit to one feature
								this.map.draw.deleteAll();
								this.map.draw.add(e.features[0]);

								this.noteLocation = this.featureToLocation(e.features[0]);
							}.bind(this),
						);

						this.map.on(
							"draw.delete",
							function () {
								this.noteLocation = null;
							}.bind(this),
						);

						this.map.on("draw.modechange", function (e) {
							if (e.mode == "simple_display") this.mode = "display";
							//the rest of the modechange states aren't being sent
						});

						this.refreshPlantingPolygons();
						this.map.draw.deleteAll();
						if (this.noteLocation) this.map.draw.add(this.locationToFeature(this.noteLocation));
						this.map.resize();
					} catch (e) {
						captureException(e);
					}
				});
			});

			//setTimeout(this.getPlantingFromUserLocation, 500);
		},
		methods: {
			locationToFeature: function (location) {
				//location is an array of objects with props latitude, longitude
				if (!location) return null;
				if (location.length == 1)
					return {
						type: "Feature",
						properties: {},
						geometry: {
							//Point coordinates are just a pair of lon,lat values
							coordinates: [location[0].longitude, location[0].latitude],
							type: "Point",
						},
					};
				else
					return {
						type: "Feature",
						properties: {},
						geometry: {
							// polygon coordinates are doubly nested pair arrays [[[0,0],[0,0]]]
							coordinates: [location.map((coord) => [coord.longitude, coord.latitude])],
							type: "Polygon",
						},
					};
			},
			featureToLocation: function (feature) {
				if (!feature) return null;
				if (feature.geometry.type == "Point")
					return [
						{
							longitude: feature.geometry.coordinates[0],
							latitude: feature.geometry.coordinates[1],
						},
					];
				else if (feature.geometry.type == "Polygon")
					//we only care about the first row
					return feature.geometry.coordinates[0].map((coord) => ({
						longitude: coord[0],
						latitude: coord[1],
					}));
				else throw "Unhandled feature geometry: " + feature.geometry.type;
			},
			centerMapOnSelectedPlantings() {
				var plantingsJson = this.getPlantingsGeoJson();

				if (plantingsJson && plantingsJson.plantings) {
					plantingsJson = plantingsJson.plantings;

					// Grab selected plantings
					plantingsJson.features = plantingsJson.features.filter((feature) => feature.properties.selected);

					// Fit map to selected planting(s)
					if (plantingsJson.features.length) {
						this.map.fitBounds(turf.bbox(plantingsJson), {
							padding: 50,
							animate: false,
						});
					}
				}
			},
			centerMapOnFilteredPlantings() {
				// Grab search-filtered plantings
				var plantingsJson = this.getPlantingsGeoJson(this.searchFilteredRegions);

				if (plantingsJson && plantingsJson.plantings) {
					plantingsJson = plantingsJson.plantings;

					// Fit map to selected planting(s)
					if (plantingsJson.features.length) {
						this.map.fitBounds(turf.bbox(plantingsJson), {
							padding: 50,
							animate: false,
						});
					}
				}
			},
			dismissModal() {
				//if we are in the middle of drawing a polygon, assume they wanted to keep it
				if (this.map.draw && this.map.draw.getMode() == "draw_polygon") {
					var features = this.map.draw.getAll().features;

					//they didn't draw anything
					if (features.length < (this.noteLocation == null ? 1 : 2)) return modalController.dismiss();

					var feature = features[features.length - 1];
					//this isn't a polygon
					if (feature.geometry.type != "Polygon") return modalController.dismiss();

					//sanity check the coordinates
					var coords = feature.geometry.coordinates[0];
					if (coords.length > 3) {
						// The second to last coordinate is a duplicate of its predecessor
						// if we are in the middle of drawing. Remove it, if so.
						var stl = coords[coords.length - 2];
						var ttl = coords[coords.length - 3];
						if (stl[0] == ttl[0] && stl[1] == ttl[1]) coords.splice(coords.length - 2, 1);
					}

					//they didn't draw enough points.
					if (coords.length < 4)
						//last point is the closing point (same as start)
						return modalController.dismiss();

					this.noteLocation = this.featureToLocation(feature);
				}

				//set the location on the parent
				// FIXME: Don't do this!
				// eslint-disable-next-line vue/no-mutating-props
				this.parent.noteLocation = this.noteLocation;

				return modalController.dismiss();
			},
			toggleFilterByMapView(value) {
				this.filterByMapView = value;
				this.computeVisiblePlantings();
			},
			computeVisiblePlantings() {
				const boundsPolygon = turf.bboxPolygon(turf.bbox(turf.lineString(this.map.getBounds().toArray())));

				const visiblePlantings = this.searchFilteredRegions.filter(
					(planting) => !turf.booleanDisjoint(boundsPolygon, turf.polygon([planting.longLats])),
				);

				this.mapVisiblePlantings = new Set(visiblePlantings.map((planting) => planting.id));
			},
			getPlantingsGeoJson(plantings) {
				plantings = plantings || this.$store.state.regions;

				if (!plantings) return "";

				var plantingsJson = {
					type: "FeatureCollection",
					features: plantings
						.filter((p) => p.longLats && p.longLats.length)
						.map((p) => {
							var coordinates = p.longLats.map((coords) => [coords[0], coords[1]]);

							return {
								id: p.id,
								type: "Feature",
								geometry: {
									type: "Polygon",
									coordinates: [coordinates],
								},
								properties: {
									selected: p.selected,
								},
							};
						}),
				};

				return {
					plantings: plantingsJson,
				};
			},
			getDrawnFeatures() {
				if (!this.map) return [];
				if (!this.map.draw) return [];
				return this.map.draw.getAll().features;
			},
			refreshPlantingPolygons() {
				var plantingsSource = this.map.getSource("plantings");

				if (!plantingsSource) return;

				var json = this.getPlantingsGeoJson(this.searchFilteredRegions);

				plantingsSource.setData(json.plantings);
			},
			selectPlanting(plantingId) {
				if (this.parent.addedPlantings.has(plantingId)) this.parent.removePlanting(plantingId);
				else this.parent.addPlanting(plantingId);

				this.refreshPlantingPolygons();
			},
			getDistanceToUser(planting) {
				if (this.currentLocation && this.currentLocation.coords) {
					var userLocation = turf.point([
						this.currentLocation.coords.longitude,
						this.currentLocation.coords.latitude,
					]);

					var poly = turf.polygon([planting.longLats.map((c) => [c[0], c[1]])]);
					var plantingCenter = turf.centerOfMass(poly);

					return turf.distance(userLocation, plantingCenter, { units: "miles" });
				}

				return 0;
			},
			placeMarker() {
				if (!this.map.draw) {
					console.error("failed to init mapbox draw");
					return;
				}
				this.addingNoteMarker = true;
				this.mode = "marker";
				this.map.draw.changeMode("draw_point");
			},
			drawPolygon() {
				if (!this.map.draw) {
					console.error("failed to init mapbox draw");
					return;
				}
				this.addingNotePolygon = true;
				this.mode = "polygon";
				this.map.draw.changeMode("draw_polygon");
			},
			removeLocation() {
				if (!this.map.draw) {
					console.error("failed to init mapbox draw");
					return;
				}
				this.addingNoteMarker = false;
				this.addingNotePolygon = false;
				this.noteLocation = null;
				this.map.draw.changeMode("simple_select");
				this.map.draw.deleteAll();
			},
		},
		computed: {
			...mapState(["currentLocation", "regions"]),
			title() {
				return {
					polygon: "Draw the Note Area",
					marker: "Place the Note Marker",
					edit: "Note Location",
					display: "Note Location",
				}[this.mode];
			},
			searchFilteredRegions() {
				const regions = this.$store.state.regions;

				// Order by checked first, then by distance to user
				return regions.filter((p) => {
					if (p.isIrrigationBlock) return false;

					if (!this.searchQuery) return true;

					const plantingName = p.environmentalRegionName + " " + p.plantingRegion;

					return new RegExp(this.searchQuery, "i").test(plantingName);
				});
			},
			orderedRegions() {
				// Ordered and filtered regions
				let regions = this.searchFilteredRegions;

				// Add 'selected' property based off of checklist selection
				regions.forEach((p) => (p.selected = this.parent.addedPlantings.has(p.id)));

				// Filter by map view if selected
				if (this.filterByMapView && this.mapVisiblePlantings)
					regions = regions.filter((p) => this.mapVisiblePlantings.has(p.id));

				// Order by checked first, then by distance to user
				return regions.sort((a, b) => {
					if (a.selected != b.selected) return a.selected > b.selected ? -1 : 1;
					else return this.getDistanceToUser(a) - this.getDistanceToUser(b);
				});
			},
			selectedPlantings() {
				let regions = this.$store.state.regions;

				return regions.reduce((acc, cur) => {
					if (this.parent.addedPlantings.has(cur.id)) {
						acc[cur.id] = true;
					}
					return acc;
				}, {});
			},
		},
		watch: {
			orderedRegions() {
				this.refreshPlantingPolygons();
			},
		},
		filters: {},
	};

	export function showSelectNoteLocation(parent, mode) {
		modalController
			.create({
				component: defineAsyncComponent(() => import("@/views/Modal/SelectNoteLocation.vue")),
				componentProps: {
					parent: parent,
					user: parent.user,
					mode: mode, //polygon, marker, display, edit
				},
				cssClass: "select-note-plantings-modal",
			})
			.then((m) => m.present());
	}
</script>
